function Media(props) {
    return (
      <div className="media-container">
        {props.media.map(item => {
          if (item.type === 'image') {
            return <img src={item.src} alt={item.alt} key={item.src} />;
          } else if (item.type === 'video') {
            return (
              <video controls key={item.src}>
                <source src={item.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            );
          }
          return null;
        })}
      </div>
    );
}

export default Media;