import React, { useState } from 'react';
import './App.css';
import Bio from './components/Bio';
import ExperienceCard from './components/ExperienceCard';
import githubIcon from './assets/github-icon.png';
import websiteIcon from './assets/website-icon.png'
import appStore from './assets/appstore-icon.png'
import articleIcon from './assets/article-icon.png'
import goodnotesLogo from './assets/goodnotes-logo.jpeg'
import forinvestLogo from './assets/forinvest-logo.jpeg'
import poolyLogo from './assets/pooly-logo.png'
import tarih_avi_1 from './assets/tarih-avi-1.png'
import tarih_avi_2 from './assets/tarih-avi-2.png'
import tarih_avi_3 from './assets/tarih-avi-3.png'
import tsp_1 from './assets/tsp-1.png'
import khan_1 from './assets/khan-1.png'
import khan_2 from './assets/khan-2.png'
import khan_3 from './assets/khan-3.png'

function App() {

  const [showRoles, setShowRoles] = useState(true);
  const [showProjects, setShowProjects] = useState(true);

  const experiences = [
    {
      id: 1,
      type: 'Role',
      title: 'Software Engineering Intern at Goodnotes',
      dates: 'May 2024 - Aug 2024',
      description: 'I developed and shipped multiple user features for an iOS/macOS application with 24 million monthly active users.',
      media: [
        {
          type: 'image', 
          src: goodnotesLogo, 
          alt: 'Goodnotes Logo'
        },
      ],
      links: [
        {
          url: 'https://www.goodnotes.com/', 
          icon: websiteIcon, 
          label: 'Goodnotes Website'
        }
      ]
    },
    {
      id: 2,
      type: 'Role',
      title: 'iOS Development Intern at ForInvest',
      dates: 'Jun 2023 - Aug 2023',
      description: 'I developed a live stock tracking application using Swift, employing MVVM architecture and AWS Lambda serverless functions. Throughout my internship, I received intensive one-on-one training in iOS design principles, cloud architecture, and key AWS services from a senior iOS developer.',
      media: [
        {
          type: 'image', 
          src: forinvestLogo, 
          alt: 'ForInvest Logo'
        },
      ],
      links: [
        {
          url: 'https://www.forinvest.com/', 
          icon: websiteIcon, 
          label: 'ForInvest Website'
        }
      ]
    },
    {
      id: 3,
      type: 'Role',
      title: 'Tech Lead at Pooly',
      dates: 'Oct 2022 - January 2024',
      description: 'I led a team of five developers in creating a mobile application for Pooly, an Istanbul-based carpooling start-up. I was responsible for managing the tech team and our workflows, carrying out 3-week feedback cycles with our tester community to improve the UX of our app, and improving our CI/CD pipelines and cloud infrastructure.',
      media: [
        {
          type: 'image', 
          src: poolyLogo, 
          alt: 'Pooly Logo'
        },
      ],
      links: [
        {
          url: 'https://apps.apple.com/tr/app/pooly/id6444834767', 
          icon: appStore, 
          label: 'Pooly App'
        }
      ]
    },
    {
      id: 4,
      type: 'Project',
      title: 'Tarih Avı (History Hunt)',
      dates: 'Feb 2020 - Aug 2020',
      description: 'I developed an iOS history trivia app called Tarih Avı ("History Hunt" in Turkish), using Swift with a Firebase backend. At its peak, Tarih Avı ranked among the top 20 trivia games on the App Store, and it remained on the leaderboard for more than a month.',
      media: [
        {
          type: 'image', 
          src: tarih_avi_1, 
          alt: 'A screenshot from Tarih Avi'
        },
        {
          type: 'image', 
          src: tarih_avi_2, 
          alt: 'Another screenshot from Tarih Avi'
        },
        {
          type: 'image', 
          src: tarih_avi_3, 
          alt: 'Another screenshot from Tarih Avi'
        },
      ],
      links: [
        {
          url: 'https://apps.apple.com/us/app/tarih-av%C4%B1/id1521842151', 
          icon: appStore, 
          label: 'Tarih Avı App'
        },
        {
          url: 'https://github.com/cemkupeli/Tarih-Avi', 
          icon: githubIcon, 
          label: 'Tarih Avı GitHub Repo'
        }
      ]
    },
    {
      id: 5,
      type: 'Project',
      title: 'Connect 4 Agent',
      dates: 'Dec 2022',
      description: 'I implemented a q-learning agent for Connect 4 in Python using an n-tuple network function approximator (link to paper below). I developed a game class and a testing script to simulate playthroughs, and I managed to achieve a win rate of 90% against a random agent.',
      media: [],
      links: [
        {
          url: 'https://github.com/cemkupeli/Connect-4', 
          icon: githubIcon, 
          label: 'Connect 4 GitHub Repo'
        },
        {
          url: 'https://link.springer.com/chapter/10.1007/978-3-642-32937-1_19', 
          icon: articleIcon, 
          label: 'n-Tuple Network Paper'
        }
      ],
    },
    {
      id: 6,
      type: 'Project',
      title: 'Runoff Election Simulator',
      dates: 'May 2021',
      description: 'I created a Python tool to determine the result of runoff elections. I ended up using this tool to determine the result of Robert College\'s (my high school) 2021 student body presidential election, which had 5 candidates.',
      media: [],
      links: [
        {
          url: 'https://github.com/cemkupeli/Runoff-Election', 
          icon: githubIcon, 
          label: 'Runoff Election GitHub Repo'
        }
      ],
    },
    {
      id: 7,
      type: 'Project',
      title: 'Traveling Salesperson Problem Simulator',
      dates: 'Mar 2021 - Apr 2021',
      description: 'I created a Java application with a GUI to visualize example graphs and simulate runtimes for different TSP algorithms.',
      media: [
        {
          type: 'image', 
          src: tsp_1, 
          alt: 'A screenshot from TSP Simulator'
        },
      ],
      links: [
        {
          url: 'https://github.com/cemkupeli/TSP-Simulator', 
          icon: githubIcon, 
          label: 'TSP Simulator GitHub Repo'
        }
      ],
    },
    {
      id: 8,
      type: 'Project',
      title: 'Khan',
      dates: 'Aug 2020',
      description: 'I created a historical strategy game with a GUI using Lua with LÖVE. The game is based on the Mongol-Chinese conflict, and it takes the player through a storyline where they can command armies in battles and make key tactical decisions.',
      media: [
        {
          type: 'image', 
          src: khan_1, 
          alt: 'A screenshot from Khan'
        },
        {
          type: 'image', 
          src: khan_2, 
          alt: 'Another screenshot from Khan'
        },
        {
          type: 'image', 
          src: khan_3, 
          alt: 'Another screenshot from Khan'
        },
      ],
      links: [
        {
          url: 'https://github.com/cemkupeli/Khan', 
          icon: githubIcon, 
          label: 'Khan GitHub Repo'
        }
      ],
    },
    {
      id: 9,
      type: 'Project',
      title: 'Hold\'em Poker Simulator',
      dates: 'Jul 2020',
      description: 'I developed a text-based Hold\'em Poker simulator in C++ using object-oriented design. I implemented features such as AI players as well as creating and loading saved games through file I/O.',
      media: [],
      links: [
        {
          url: 'https://github.com/cemkupeli/Holdem-Poker', 
          icon: githubIcon, 
          label: 'Hold\'em Poker GitHub Repo'
        }
      ],
    },
  ];

  return (
    <div className="App">
      <div className="bio-container">
        <Bio />
      </div>

      <div className="toggle-section">
        <button onClick={() => setShowRoles(!showRoles)}>
          {showRoles ? 'Hide' : 'Show'} Roles
        </button>
        <button onClick={() => setShowProjects(!showProjects)}>
          {showProjects ? 'Hide' : 'Show'} Projects
        </button>
      </div>

      <div className="experiences">
        {experiences.map(exp => {
          if ((exp.type === 'Role' && showRoles) || (exp.type === 'Project' && showProjects)) {
            return <ExperienceCard key={exp.id} className={exp.type} {...exp} />;
          }
          return null; // Return null if the experience type should not be shown
        })}
      </div>
    </div>
  );
}

export default App;
