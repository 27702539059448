import React from 'react';
import Media from './Media';
import IconLink from './IconLink';

function ExperienceCard(props) {
    return (
      <div className={`experience-card ${props.className}`}>
        <h2>{props.title}</h2>
        <div className="dates-container">
            <span className="dates">{props.dates}</span>
            <span className="type-label">{props.type}</span> {/* This will be either "Project" or "Role" */}
        </div>
        <p className="description">{props.description}</p>
        {props.media && <Media media={props.media} />}
        {props.links && props.links.map(link => <IconLink key={link.url} {...link} />)}
      </div>
    );
  }

export default ExperienceCard;